import React from "react";

function Profile(){

    return(
        <div>
            <img 
                src='./p/k.jpg' 
                style={{
                    width: 200,
                    height: 200,
                    borderRadius: 100
                }} 
                alt='Kamolesh Bachar'
            />

            <h1>Kamolesh Bachar</h1>
        </div>
    )
}

export default Profile;